<template>
  <div class="takeoutIframe">
    <!--    <van-icon name="arrow-left" size="20" color="white" @click="handleReturn"/>-->
    <iframe :src="url" frameborder="0" v-if="url" style="width:100%;height: 100%;position: fixed; top:0"></iframe>
  </div>
</template>
<script>
import Vue from 'vue'
import { Icon } from 'vant'

Vue.use(Icon)
export default {
  name: 'takeoutIframe',
  data () {
    return {
      url: '',
    }
  },
  created () {
    this.url = decodeURIComponent(this.$route.query.url)
  },
  methods: {
    // 顶部返回按钮
    handleReturn () {
      this.$router.go(-1)
    },
  }
}

</script>
<style lang="less" scoped>
/deep/ .van-icon {
  z-index: 100;
  //padding: 10px;
  position: fixed;
  top: 10px;
  left: 2px;
}

.takeoutIframe {
}
</style>
